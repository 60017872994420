import React from "react"
import Layout from "../components/Layout"
import BetriebeSorted from "../components/Betriebe/BetriebeSorted"

const Betriebepage = props => {
  return (
    <Layout
      title="Unsere Betriebe"
      secondTitle={"Mitglieder der WirtschaftsPlattForm"}
      small={true}
      fluid={null}
      seoTitle="WirtschaftsPlattForm Schwechat | Betriebe"
    >
      <section className="section betriebe">
        <div className="container">
          <BetriebeSorted />
        </div>
      </section>
    </Layout>
  )
}

export default Betriebepage
