import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import LogoSvg from "../../images/SW-blau.svg"
import Image from "gatsby-image"

const getBrancheAndBetriebe = graphql`
  query BrancheAndBetriebe {
    allContentfulBetrieb(sort: { fields: branche }) {
      edges {
        node {
          id
          name
          angebot
          website
          telefon
          festnetz
          email
          adresse
          plzOrt
          branche
          oeffnungszeitMontag
          oeffnungszeitDienstag
          oeffnungszeitMittwoch
          oeffnungszeitDonnerstag
          oeffnungszeitFreitag
          oeffnungszeitSamstag
          oeffnungszeitSonntag
          spezielleOeffnungszeiten
          logo {
            fluid(maxWidth: 1280, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          portal {
            fluid(maxWidth: 1280, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
export const BetriebeButton = props => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <button
        id={props.id}
        key={props.id + "-key"}
        // counter={props.counter}
        className="button is-link mt-1 mb-1"
        onClick={() => setOpen(!open)}
      >
        {props.name}
      </button>
      <div className={`modal ${open ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setOpen(!open)}
          onKeyDown={() => setOpen(!open)}
          role="button"
          aria-label="dialog öffnen"
          tabIndex={props.counter}
        ></div>

        <div className="modal-card">
          <header className="modal-card-head is-block">
            <div className="is-flex is-justify-content-space-between is-align-items-center">
              {props.logo ? (
                <a
                  href={`https://${props.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    fluid={props.logo.fluid}
                    alt="Logo"
                    className="modalLogo is-hidden-touch"
                  />
                </a>
              ) : (
                <img
                  src={LogoSvg}
                  alt="Logo"
                  className="modalLogo is-hidden-touch"
                />
              )}
              <p className="modal-card-title">{props.name}</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => setOpen(!open)}
                onKeyDown={() => setOpen(!open)}
              ></button>
            </div>
            <div className="is-flex is-hidden-tablet is-justify-content-center	">
              {props.logo ? (
                <a
                  href={`https://${props.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    fluid={props.logo.fluid}
                    alt="Logo"
                    className="mt-3 modalLogo"
                  />
                </a>
              ) : (
                <img src={LogoSvg} alt="Logo" className="modalLogo mt-3" />
              )}
            </div>
          </header>
          <section className="modal-card-body is-size-5 has-text-centered">
            {props.portal ? (
              <div>
                <Image fluid={props.portal.fluid} />
                <hr className="dropdown-divider" />
              </div>
            ) : (
              <></>
            )}
            <p className="has-text-dark">{props.angebot}</p>
            <hr className="dropdown-divider" />
            {props.telefon ? (
              <div>
                <p className="has-text-dark">Mobil:</p>
                <a className="has-text-link" href={`tel:${props.telefon}`}>
                  {props.telefon}
                </a>
                <hr className="dropdown-divider" />
              </div>
            ) : (
              <></>
            )}
            {props.festnetz ? (
              <div>
                <p className="has-text-dark">Festnetz:</p>
                <a className="has-text-link" href={`tel:${props.festnetz}`}>
                  {props.festnetz}
                </a>
                <hr className="dropdown-divider" />
              </div>
            ) : (
              <></>
            )}
            <p className="has-text-dark">Email:</p>
            <a className="has-text-link" href={`mailto:${props.email}`}>
              {props.email}
            </a>
            <hr className="dropdown-divider" />
            <div></div>
            {props.website ? (
              <div>
                <p className="has-text-dark mt-3">Website</p>
                <a
                  href={`https://${props.website}`}
                  aria-label={`Link zur Firmenseite ${props.website}`}
                  className="has-text-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.website}
                </a>
                <hr className="dropdown-divider" />
              </div>
            ) : (
              <></>
            )}
            <p className="has-text-dark pb-3">Öffnungszeiten:</p>
            <div className="has-text-left">
              <div className="columns is-mobile">
                <div className="column">
                  <p className="has-text-dark ">Montag:</p>
                </div>
                <div className="column">
                  <p className="has-text-dark ">{props.oeffnungszeitMontag}</p>
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column">
                  <p className="has-text-dark ">Dienstag:</p>
                </div>
                <div className="column">
                  <p className="has-text-dark ">
                    {props.oeffnungszeitDienstag}
                  </p>
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column">
                  <p className="has-text-dark ">Mittwoch:</p>
                </div>
                <div className="column">
                  <p className="has-text-dark ">
                    {props.oeffnungszeitMittwoch}
                  </p>
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column">
                  <p className="has-text-dark ">Donnerstag:</p>
                </div>
                <div className="column">
                  <p className="has-text-dark ">
                    {props.oeffnungszeitDonnerstag}
                  </p>
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column">
                  <p className="has-text-dark ">Freitag:</p>
                </div>
                <div className="column">
                  <p className="has-text-dark ">{props.oeffnungszeitFreitag}</p>
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column">
                  <p className="has-text-dark ">Samstag:</p>
                </div>
                <div className="column">
                  <p className="has-text-dark ">{props.oeffnungszeitSamstag}</p>
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column">
                  <p className="has-text-dark ">Sonntag:</p>
                </div>
                <div className="column">
                  <p className="has-text-dark ">{props.oeffnungszeitSonntag}</p>
                </div>
              </div>
              {props.spezielleOeffnungszeiten ? (
                <div className="columns is-mobile">
                  <div className="column">
                    <p className="has-text-dark ">Spezielle Öffnungszeiten:</p>
                  </div>
                  <div className="column">
                    <p className="has-text-dark ">
                      {props.spezielleOeffnungszeiten}
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <hr className="dropdown-divider mt-4" />
            <p className="has-text-dark pb-3">Adresse:</p>
            <p className="has-text-dark">{props.adresse}</p>
            <p className="has-text-dark">{props.plzOrt}</p>
          </section>
          <footer className="modal-card-foot">
            <div className="has-text-centered">
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${props.name}+schwechat`}
                className="button is-medium is-link is-uppercase"
                target="_blank"
                rel="noreferrer"
              >
                Anfahrt
              </a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

let branchen = []

const BetriebeSorted = () => {
  const data = useStaticQuery(getBrancheAndBetriebe)
  branchen = data.allContentfulBetrieb.edges.map(({ node }) => node.branche)

  const branchenFiltered = branchen.filter(
    (branche, index) => branchen.indexOf(branche) === index
  )

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-multiline">
          {branchenFiltered.map((branche, index) => {
            return (
              <div className="column is-4" key={index}>
                <aside className="menu">
                  <p className="menu-label is-size-5">{branche}</p>
                  <ul className="menu-list">
                    <li>
                      <ul>
                        {data.allContentfulBetrieb.edges.map(({ node }) => {
                          return node.branche === branche ? (
                            <li key={node.id}>
                              <BetriebeButton
                                logo={node.logo}
                                portal={node.portal}
                                id={"id-" + node.id}
                                website={node.website}
                                name={node.name}
                                telefon={node.telefon}
                                festnetz={node.festnetz}
                                email={node.email}
                                adresse={node.adresse}
                                plzOrt={node.plzOrt}
                                oeffnungszeitMontag={node.oeffnungszeitMontag}
                                oeffnungszeitDienstag={
                                  node.oeffnungszeitDienstag
                                }
                                oeffnungszeitMittwoch={
                                  node.oeffnungszeitMittwoch
                                }
                                oeffnungszeitDonnerstag={
                                  node.oeffnungszeitDonnerstag
                                }
                                oeffnungszeitFreitag={node.oeffnungszeitFreitag}
                                oeffnungszeitSamstag={node.oeffnungszeitSamstag}
                                oeffnungszeitSonntag={node.oeffnungszeitSonntag}
                                spezielleOeffnungszeiten={
                                  node.spezielleOeffnungszeiten
                                }
                                angebot={node.angebot}
                              />
                            </li>
                          ) : (
                            ""
                          )
                        })}
                      </ul>
                    </li>
                  </ul>
                </aside>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
export default BetriebeSorted
